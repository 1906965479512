<script setup>
import { computed } from 'vue'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
} from '@heroicons/vue/24/outline'

const props = defineProps({
  type: {
    type: String,
    default: 'standard',
    validator: value => ['success', 'error', 'warning', 'info', 'standard'].includes(value),
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
})

const iconComponent = computed(
  () =>
    ({
      success: CheckCircleIcon,
      error: ShieldExclamationIcon,
      warning: ExclamationTriangleIcon,
      info: ExclamationCircleIcon,
      standard: null,
    })[props.type]
)
</script>

<template>
  <div
    class="flex flex-1 items-stretch gap-4 rounded-lg border p-4"
    :class="{
      'border-success bg-success/20 text-success-content dark:text-success': type === 'success',
      'border-error bg-error/20 text-error-content': type === 'error',
      'border-warning bg-warning/10 text-warning-content dark:text-warning': type === 'warning',
      'border-info bg-info/20': type === 'info',
      'border-gray-400 bg-gray-400/20': !type,
    }"
  >
    <component :is="iconComponent" v-if="showIcon" class="w-4" />

    <div class="flex flex-col gap-1">
      <div v-if="title" class="text-xs font-semibold">
        {{ title }}
      </div>

      <div class="flex h-full items-center gap-2 text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
