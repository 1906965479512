<setup lang="ts"></setup>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { useVModel } from '@/composables/vue'

export interface UiDialogProps {
  isOpen: boolean
}
export type UiDialogEmits = {
  'update:isOpen': [value: boolean]
  /** Emits after dialog closing animation is fully done */
  didClose: []
  clickOutside: []
}

interface Slots {
  default(slotProps: SlotProps): unknown
}

interface SlotProps {
  closeAndWaitTillAnimated: () => Promise<void>
}

const LEAVE_DURATION_200 = 200
const CONFIG_CLASSES = {
  [LEAVE_DURATION_200]: 'duration-300',
}
const LEAVE_CLASSES = `${CONFIG_CLASSES[LEAVE_DURATION_200]} ease-in`

const props = defineProps<UiDialogProps>()
const emit = defineEmits<UiDialogEmits>()
defineSlots<Slots>()
const slotProps = computed((): SlotProps => ({ closeAndWaitTillAnimated }))

const isOpenModel = useVModel(props, 'isOpen', emit)
const closingAnimationPromise = ref<Promise<void> | null>(null)
watch(
  () => props.isOpen,
  (isOpen, wasOpen) => {
    if (!isOpen && wasOpen) {
      finishAnimationThenClose()
    }
  },
  { immediate: true }
)

function finishAnimationThenClose(): Promise<void> {
  if (closingAnimationPromise.value != null) {
    // Already animating
    return closingAnimationPromise.value
  }
  closingAnimationPromise.value = wait(LEAVE_DURATION_200).then(() => emit('didClose'))
  return closingAnimationPromise.value
}

function wait(ms: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(resolve, ms)
  })
}

/** Meant to be used in scoped slot. Will wait for closing animation to finish, if there is any. */
function closeAndWaitTillAnimated(): Promise<void> {
  isOpenModel.value = false
  return finishAnimationThenClose()
}

function onClickOutside(): void {
  emit('clickOutside')
}
</script>

<template>
  <TransitionRoot appear as="template" :show="isOpenModel">
    <Dialog as="div" @close="onClickOutside">
      <div
        class="fixed inset-0 z-10 overflow-y-auto"
        :class="{
          // prevent interactions during closing animation
          'pointer-events-none': !isOpenModel,
        }"
      >
        <div class="flex min-h-screen items-center justify-center px-4 text-center">
          <!-- backdrop -->
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            :leave="LEAVE_CLASSES"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div aria-hidden="true" class="fixed inset-0 bg-gray-800/20 dark:bg-gray-100/20" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            :leave="LEAVE_CLASSES"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel
              class="my-4 inline-block w-full max-w-md transform overflow-hidden rounded-box bg-light-fore p-4 text-left align-middle shadow-xl transition-all dark:bg-dark-fore md:p-8"
            >
              <slot v-bind="slotProps" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
