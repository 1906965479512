<script setup lang="ts">
import { ref, watch } from 'vue'

import { useConfirmData } from '@/utils/confirm'

import UiConfirm from './ui-confirm.vue'

const { currentConfirmConfig: config, setConfirmResult } = useConfirmData()

const isOpen = ref(false)

const localConfig = ref<(typeof config)['value']>(null)

watch(
  config,
  newConfig => {
    if (newConfig) {
      localConfig.value = newConfig
      isOpen.value = true
    }
    // if newConfig is null, we are preserving the local config until closing animation is done
  },
  { deep: true, immediate: true }
)

function onAnimationEnd() {
  if (!isOpen.value) {
    localConfig.value = null
  }
}
</script>

<template>
  <UiConfirm
    v-if="localConfig"
    v-model:isOpen="isOpen"
    :alerts="localConfig?.alerts"
    :approveText="localConfig?.approveText"
    :body="localConfig?.body"
    :cancelText="localConfig?.cancelText"
    :title="localConfig?.title"
    @approve="setConfirmResult(true)"
    @cancel="setConfirmResult(false)"
    @did-close="onAnimationEnd"
  />
</template>
