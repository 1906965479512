<script setup lang="ts">
import AlertBox from '@/components/alert-box.vue'
import { useVModel } from '@/composables/vue'
import { UiDialog } from '@/ui/ui-dialog'
import { UiDialogPage } from '@/ui/ui-dialog-page'
import { ConfirmConfig } from '@/utils/confirm'

export interface Props extends ConfirmConfig {
  isOpen: boolean
}

export type Emits = {
  'update:isOpen': [value: boolean]
  approve: []
  cancel: []
  /** Emits after dialog closing animation is fully done */
  didClose: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const isOpenModel = useVModel(props, 'isOpen', emit)

function takeAction(actionToTake: 'approve' | 'cancel') {
  isOpenModel.value = false
  switch (actionToTake) {
    case 'approve':
      emit('approve')
      break
    case 'cancel':
      emit('cancel')
      break
    default:
      break
  }
}
</script>

<template>
  <UiDialog
    v-model:isOpen="isOpenModel"
    @click-outside="takeAction('cancel')"
    @did-close="emit('didClose')"
  >
    <UiDialogPage
      :cancelButtonText="cancelText"
      :submitButtonText="approveText"
      :title="title"
      @cancel="takeAction('cancel')"
      @submit="takeAction('approve')"
    >
      <p>{{ body }}</p>

      <div v-if="alerts?.length" class="flex flex-col gap-3">
        <AlertBox
          v-for="(alert, i) in alerts ?? []"
          :key="i"
          :title="alert?.title"
          :type="alert.type"
        >
          {{ alert.message }}
        </AlertBox>
      </div>
    </UiDialogPage>
  </UiDialog>
</template>
