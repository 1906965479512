import { computed, Reactive, reactive, WritableComputedRef } from 'vue'

import _cloneDeep from 'lodash/cloneDeep'

import { FullyOptional } from '@/types/type-helpers'
import { getTypedKeys } from '@/utils/object'

/**
 * Creates a writable computed property that can be used in v-model bindings
 * @param props - The components props object
 * @param propKey - The key of the prop to bind to
 * @param emit - The components emit function. Must be the `update:` version of the prop key
 */
export function useVModel<
  TProps,
  TKey extends Exclude<keyof TProps, number | symbol>,
  TEmit extends (event: `update:${TKey}`, payload: TProps[TKey]) => void,
>(props: TProps, propKey: TKey, emit: TEmit): WritableComputedRef<TProps[TKey]> {
  return computed({
    get() {
      return props[propKey]
    },
    set(val) {
      emit(`update:${propKey}`, val)
    },
  })
}

interface UseReactiveResult<TObj extends object> {
  objReactive: Reactive<TObj>
  /** Resets the reactive object back to its empty shape, while maintaining reactivity */
  resetReactive: () => void
  /** Applies all properties of a target object to the reactive object, while maintaining reactivity */
  setReactive: (newObj: FullyOptional<TObj>) => void
  /** Clones and converts the reactive object back to a plain object */
  reactiveToObject: () => TObj
}

export function useReactive<TObj extends object>(
  emptyFactory: () => TObj
): UseReactiveResult<TObj> {
  const objReactive = reactive(emptyFactory())
  // eslint-disable-next-line func-style
  const resetReactive = () => reset(objReactive, emptyFactory())
  // eslint-disable-next-line func-style
  const setReactive = (newObj: FullyOptional<TObj>) => setReactiveProperties(objReactive, newObj)
  // eslint-disable-next-line func-style
  const reactiveToObject = () => toObject(objReactive)
  return { objReactive, resetReactive, setReactive, reactiveToObject }
}

function setReactiveProperties<TObj extends object>(
  current: Reactive<TObj>,
  newObj: FullyOptional<TObj>
): void {
  getTypedKeys(newObj).forEach(key => {
    // @ts-expect-error --- We know the key exists on both objects
    current[key] = newObj[key]
  })
}

function reset<TObj extends object>(current: Reactive<TObj>, empty: TObj): void {
  // Making empty reactive to satisfy TS, so that it understands that our keys belong to both objects
  const emptyReactive = reactive(empty)
  return getTypedKeys(current).forEach(key => {
    if (key in empty) {
      current[key] = emptyReactive[key]
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete current[key]
    }
  })
}

function toObject<TObj extends object>(reactiveObj: Reactive<TObj>): TObj {
  // deep cloning will remove reactivity, however TS doesn't know that, so we need a type cast
  return _cloneDeep(reactiveObj) as TObj
}
