import { ref } from 'vue'

import { destructurePromise } from '@/utils/promises'

export interface ConfirmAlert {
  type: 'warning' | 'error'
  title?: string
  message: string
}

export interface ConfirmConfig {
  title: string
  body: string
  alerts?: ConfirmAlert[] | null
  approveText: string
  cancelText: string
}

const currentConfirmConfig = ref<ConfirmConfig | null>(null)
// separating promise and its resolve to be able to await it from one place,
// and resolve it from another (from the centralized component)
let currentPromiseDestructured: ReturnType<typeof destructurePromise<boolean>> | null = null

export function confirm(options: ConfirmConfig): Promise<boolean> {
  currentConfirmConfig.value = options
  currentPromiseDestructured = destructurePromise<boolean>()
  const [promise] = currentPromiseDestructured
  return promise
}

function setConfirmResult(didApprove: boolean): void {
  if (!currentConfirmConfig.value || !currentPromiseDestructured) {
    throw new Error('Must call confirm() before resolving')
  }
  const [, resolve] = currentPromiseDestructured
  resolve(didApprove)
  currentConfirmConfig.value = null
  currentPromiseDestructured = null
}

export function useConfirmData() {
  return {
    currentConfirmConfig,
    setConfirmResult,
  }
}
