export type ResolvePromise<T> = (value: T) => void
export type RejectPromise = (reason?: Error) => void

export function destructurePromise<T>(): [Promise<T>, ResolvePromise<T>, RejectPromise] {
  // eslint-disable-next-line func-style
  let resolve: ResolvePromise<T> = () => {}
  // eslint-disable-next-line func-style
  let reject: RejectPromise = () => {}
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })
  return [promise, resolve, reject] as const
}
