import i18n from '@/i18n'
import MainLayout from '@/layouts/MainLayout.vue'
import { db } from '@/plugins/db'
import store from '@/state/store'

import { ROUTES } from './constants'

const { t } = i18n.global

/** @type {import('vue-router').RouterOptions['routes']} */
export default [
  // These auth helper pages will never be reached via the router.
  // Adding these only here to indicate that the URLs are taken.
  // We are rewriting to the statically hosted .html files,
  // that can be found under the same path in the public folder.
  // Locally, the dev server is taking care of the rewrite.
  // When deploying to a web server, a reverse proxy (independent or built in web server)
  // should handle directing incoming traffic to the proper files if they match these path
  { path: '/__/auth/handler' },
  { path: '/__/auth/iframe' },

  {
    // If 3rd party cookies are blocked, we can't use the idToken from the firebase auth flow.
    // The user need to login one more time, directly with MSAL.
    // Therefore we have this separate redirect page.
    path: '/autentisering/logga-in',
    name: ROUTES.AUTH_MSAL,
    component: () => import('@/views/AuthMsalRedirect.vue'),
    meta: { authRequired: false, title: t('auth.signing-in') },
  },

  // WRAPPED IN MAIN LAYOUT
  {
    path: '/',
    component: MainLayout,
    // all children wrapped in layout
    children: [
      {
        path: '/',
        name: ROUTES.HOME,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Facilities.vue'),
      },
      {
        path: '/overview',
        redirect: '/',
        replace: true,
      },
      {
        path: '/settings',
        name: ROUTES.SETTINGS,
        meta: { authRequired: true, title: t('settings.title') },
        component: () => import('@/views/settings-view.vue'),
      },
      {
        path: '/facility/:facilityId',
        meta: {
          authRequired: true,
          beforeResolve(to, _, next) {
            if (store.currentStation && store.currentStation.id === to.params.facilityId) next()
            else
              db.collection('vehicleStations')
                .doc(to.params.facilityId)
                .get()
                .then(snapshot => {
                  if (!snapshot.exists) {
                    next({ path: '/', replace: true })
                  } else {
                    const doc = snapshot.data()
                    store.commit('SET_STATE', { currentStation: doc })

                    next()
                  }
                })
          },
        },
        redirect: to => ({ name: ROUTES.OVERVIEW, params: to.params }),
        children: [
          {
            path: 'overview',
            name: ROUTES.OVERVIEW,
            meta: { authRequired: true },
            component: () => import('@/views/Overview.vue'),
          },
          {
            path: 'plot/:plotName',
            name: ROUTES.PLOT,
            meta: { authRequired: true },
            component: () => import('@/views/ViewPlot.vue'),
          },
        ],
      },
      {
        path: '/finance',
        name: ROUTES.SUMMARIES,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Summaries.vue'),
      },
      {
        path: '/finance/order/:orderId',
        name: ROUTES.SUMMARY,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Summary.vue'),
      },
      {
        path: '/lm',
        name: ROUTES.LASTMILE,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Lastmile.vue'),
      },
      {
        path: '/avvikelse',
        name: ROUTES.TL_OVERVIEW,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Avvikelse.vue'),
      },
      {
        path: '/kpi',
        name: ROUTES.KPI,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/Kpi.vue'),
      },
      {
        path: '/kalender',
        name: ROUTES.PULSE,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/PulseCalendar.vue'),
      },
      {
        path: '/convert',
        name: ROUTES.CONVERT,
        meta: {
          authRequired: true,
        },
        component: () => import('@/views/ConvertToParked.vue'),
      },
    ],
  },

  // OUTSIDE MAIN LAYOUT

  {
    path: '/:path(.*)',
    name: ROUTES.ERROR,
    component: () => import('@/views/ErrorPage.vue'),
  },
]
